import { createHeaders } from '@wix/headers';
import { HttpClient } from '@wix/http-client';
import type { GetProductsListQuery } from './__generated__/GetProductsList';
import { GetProductsListDocument } from './__generated__/GetProductsList';
import type { WixStoresProductsList } from './getWixStoresProductsList.types';

export async function getWixStoresProductsList(
  signedInstance: string,
): Promise<WixStoresProductsList> {
  const headers = createHeaders({
    signedInstance,
  });
  const httpClient = new HttpClient({
    headers,
  });

  try {
    const result = await httpClient.post<{
      data: GetProductsListQuery;
    }>('/_api/wixstores-graphql-server/graphql', {
      query: GetProductsListDocument,
      variables: {
        filters: {
          visible: true,
        },
        limit: 100000,
        offset: 0,
      },
      operationName: 'getProductsList',
      source: 'WixStoresWebClient',
    });
    return result.data.data.catalog.products.list;
  } catch (e) {
    // TODO: add sentry
    return [];
  }
}
