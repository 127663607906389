import { createHeaders } from '@wix/headers';
import { HttpClient } from '@wix/http-client';
import type {
  SetWixStoresProductsMediaItemsResponse,
  WixStoresProductsMediaItems,
} from './setWixStoresProductsMediaItems.types';

export async function setWixStoresProductsMediaItems(
  signedInstance: string,
  items: WixStoresProductsMediaItems,
): Promise<SetWixStoresProductsMediaItemsResponse> {
  if (!items.length) {
    return {
      success: true,
    };
  }

  const headers = createHeaders({
    signedInstance,
  });
  const httpClient = new HttpClient({
    headers,
  });

  try {
    const { data } =
      await httpClient.post<SetWixStoresProductsMediaItemsResponse>(
        '/_api/wix-ecommerce-renderer-web/store-manager/executeCatalogCommands',
        {
          commands: items.map((data) => ({
            name: 'UpdateProductMediaItem',
            data,
          })),
        },
      );
    return data;
  } catch (e) {
    // TODO: add sentry
    return {
      success: false,
    };
  }
}
