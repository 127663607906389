import type { Container } from 'inversify';
import { EditorStoreService } from '../services/EditorStoreService/EditorStoreService';
import type { IEditorStoreService } from '../services/EditorStoreService/EditorStoreService.types';
import { editorServices } from './services';

export function inversifyEditorConfig(container: Container) {
  container
    .bind<IEditorStoreService>(editorServices.store)
    .to(EditorStoreService)
    .inSingletonScope();
}
